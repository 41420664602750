import React from "react"
import { TrustClientData } from "./data"
import AppsStoreIcon from "../../images/appstore.svg"
import PlayStoreIcon from "../../images/playstore.svg"
const TrustedClient = ({ extraClassName }) => {
  const { image, respimage, text } = TrustClientData
  return (
    <section className={`commonSection trustClient ${extraClassName}`} id="trustClient">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="CL_content">
              <img className="d-none d-lg-block" src={image} height='auto' width= "auto" alt="CL_content" loading="lazy"/>
              <img
                className="d-md-block d-xs-block d-lg-none"
                src={respimage}
                alt=""
                loading="lazy"
                height='auto' width= "auto"
              />
              <div className="abc_inner">
                <div className="row">
                  <div className="col-lg-5 col-sm-12 col-md-5"></div>
                  <div className="col-lg-7 col-sm-12 col-md-12">
                    <div className="abci_content">
                      <h2>
                        Your scores, your way,{" "}
                        <span className="theme-red">LIVE</span> in your devices
                      </h2>
                      <p>{text}</p>
                      <a
                        onClick={e => {
                          window.open(process.env.GATSBY_IOS_STOREURL)
                        }}
                        target="_blank"
                        href="#"
                        aria-label="IOS"
                      >
                        <img
                          src={AppsStoreIcon}
                          alt="appstore"
                          className="app-btn-home"
                          loading="lazy"
                          height="auto"
                          width="auto"
                        />
                      </a>
                      <span>&nbsp;&nbsp;</span>
                      <a
                        onClick={e => {
                          window.open(process.env.GATSBY_ANDROID_STOREURL)
                        }}
                        href="#"
                        target="_blank"
                        aria-label="android"
                      >
                        <img
                          src={PlayStoreIcon}
                          alt="playstore"
                          className="app-btn-home"
                          loading="lazy"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TrustedClient
