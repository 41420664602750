import aboutOne01 from "@/images/about/1.webp"
import aboutOne02 from "@/images/about/2.webp"

export const AboutOneData = {
  sectionContent: {
    title: "Building amazing cloud-based LIVE scores platform.",
    subTitle: "get to know us",
    text: "We are committed to providing our customers with exceptional service while offering our employees the best training. Lorem Ipsum is simply dummy text of the printing and typesetting industry has been the industry's standard dummy text ever since the 1500s.",
  },
  counter: {
    title: "Scoring Since",
    number: 2022,
  },
  button: {
    label: "Learn More",
    url: "#",
  },
  gallery: [aboutOne01, aboutOne02],
}
