import desktopImg01 from "@/images/slider/desktop-1.webp"
import desktopImg02 from "@/images/slider/desktop-2.webp"
import desktopImg03 from "@/images/slider/desktop-3.webp"
import desktopImg04 from "@/images/slider/desktop-4.webp"
import desktopImg05 from "@/images/slider/desktop-5.webp"
import tabImg01 from "@/images/slider/tab-1.webp"
import tabImg02 from "@/images/slider/tab-2.webp"
import tabImg03 from "@/images/slider/tab-3.webp"
import tabImg04 from "@/images/slider/tab-4.webp"
import tabImg05 from "@/images/slider/tab-5.webp"
import mobileImg01 from "@/images/slider/mobile-1.webp"
import mobileImg02 from "@/images/slider/mobile-2.webp"
import mobileImg03 from "@/images/slider/mobile-3.webp"
import mobileImg04 from "@/images/slider/mobile-4.webp"
import mobileImg05 from "@/images/slider/mobile-5.webp"

export const SliderData = [
  {
    Desktopimage: desktopImg01,
    Tabimage: tabImg01,
    Mobileimage: mobileImg01,
    subTitle: "manage sporting events and live scores",
    title: "CLOUD \n SCORING PLATFORM",
    button: {
      label: "SIGNUP FREE",
      url: process.env.GATSBY_HQ_URL,
    },
  },
  {
    Desktopimage: desktopImg05,
    Tabimage: tabImg05,
    Mobileimage: mobileImg05,
    subTitle: "Win Fabulous Prizes",
    title: "PREDICT AND WIN",
    button: {
      label: "DOWNLOAD NOW",
      playStoreUrl: 'https://play.google.com/store/apps/details?id=com.scoreblox',
      appStoreUrl: 'https://apps.apple.com/us/app/scoreblox-live-scores/id1617679593?platform=iphone',
      url: process.env.GATSBY_HQ_URL,
    },
  },
  {
    Desktopimage: desktopImg02,
    Tabimage: tabImg02,
    Mobileimage: mobileImg02,
    subTitle: "manage cricket events and live scores",
    title: "CRICKET \n SCORING PLATFORM",
    button: {
      label: "SIGNUP FREE",
      url: process.env.GATSBY_HQ_URL,
    },
  },
  {
    Desktopimage: desktopImg03,
    Tabimage: tabImg03,
    Mobileimage: mobileImg03,
    subTitle: "manage football events and live scores",
    title: "FOOTBALL \n SCORING PLATFORM",
    button: {
      label: "SIGNUP FREE",
      url: process.env.GATSBY_HQ_URL,
    },
  },
  {
    Desktopimage: desktopImg04,
    Tabimage: tabImg04,
    Mobileimage: mobileImg04,
    subTitle: "manage rugby events and live scores",
    title: "RUGBY \n SCORING PLATFORM",
    button: {
      label: "SIGNUP FREE",
      url: process.env.GATSBY_HQ_URL,
    },
  },
]
