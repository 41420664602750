import React, { useState, useEffect } from "react";
import { Link } from "gatsby"

const SliderButton = ({button}) => {

  const [isMac, setIsMac] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  // const [isWindows, setIsWindows] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/iPad|iPhone|iPod/i.test(userAgent)) {
      setIsMac(true);
    } else if (/android/i.test(userAgent)) {
      setIsAndroid(true);
    } 
    // else if (/Windows/i.test(userAgent)) {
    //   setIsWindows(true);
    // }
  }, []);

  return ( 
    <section>
      <Link
        to={`${button.label === "DOWNLOAD NOW" ? ( isMac ? button.appStoreUrl : isAndroid ? button.playStoreUrl : '#trustClient') : `${process.env.GATSBY_HQ_URL}`}`}
        className={`common_btn`}
        target={`${isMac || isAndroid ? (button.label === "DOWNLOAD NOW" ? '_blank' : '') : ""}`}
        rel="noreferrer"
      >
        <span>{button.label}</span>
      </Link>
    </section>
  )
}

export default SliderButton
