import React from "react"
import Layout from "@/components/layout"
import HeaderOne from "@/components/header-one"
import DesktopSlider from "@/components/sliders/desktop-slider"
import TabSlider from "@/components/sliders/tab-slider"
import MobileSlider from "@/components/sliders/mobile-slider"
import ParallaxOne from "@/components/parallax-one"
import Footer from "@/components/footer-one"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"

import FunfactOne from "@/components/funfact-one"
import TrustedClient from "@/components/trusted-client"
import AboutOne from "@/components/about-one"
import HomeSectionOne from "@/components/home-sec-one"


const HomeOne = props => {
  const location = props.location.pathname
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Home">
          <HeaderOne />
          <DesktopSlider />
          <TabSlider />
          <MobileSlider />
          <HomeSectionOne />
          <AboutOne />
          <ParallaxOne />
          <FunfactOne />
          <TrustedClient extraClassName="bottom-mar" />
          <Footer location={location} />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  )
}

export default HomeOne
