// Features...
export const HomeOneData = {
  sectionContent: {
    title: "cloud-based SPORTS LEAGUE MANAGEMENT \n for popular sports.",
    subTitle: "Sports Features Galore",
    text:
      "Easy to manage, easy to enter cloud-based score management solution \nfor your cricket, football and other clubs.",
  },
  posts: [
    {
      title: "Mobile",
      iconName: "icon-iphone",
      // url: "/feature",Cl
    },
    {
      title: "Tablet",
      iconName: "icon-tablet",
      // url: "/feature",
    },
    {
      title: "Watch",
      iconName: "icon-watch",
      // url: "/feature",
    },
    {
      title: "TV",
      iconName: "icon-tv",
      // url: "/feature",
    },
    {
      title: "Desktop",
      iconName: "icon-desktop",
      // url: "/feature",
    },
  ],
};

  // mei-web-design