import React from "react"
import { AboutOneData } from "./data"
import { Row, Container } from "react-bootstrap"

const AboutOne = () => {
  const { sectionContent, gallery, counter } = AboutOneData
  return (
    <section className="commonSection">
      <Container>
        <Row>
          <div className="col-lg-6 col-sm-12 col-md-6">
            <h4 className="sub_title"> {sectionContent.subTitle} </h4>
            <h2 className="sec_title"> {sectionContent.title} </h2>
          </div>
          <div className="col-lg-6 col-sm-12 col-md-6">
            <div className="agency_img1">
              <img src={gallery[0]} alt="img1" loading="lazy"/>
            </div>
          </div>
          <div className="col-lg-12 col-sm-12">
            <div className="agency_img2">
              <img src={gallery[1]} alt="img2" loading="lazy"/>
            </div>
            <div className="compay_date">
              <span> {counter.title} </span>
              <h2> {counter.number} </h2>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  )
}

export default AboutOne
