import React from "react";

const HomeCardOne = ({ data }) => {
  const { iconName, title } = data;
  return (
    <section className="icon_box_1 text-center" aria-label={title}>
      <div className="flipper">
        <div className="front">
          <i className={iconName}></i>
          <h3>{title}</h3>
        </div>
        <div className="back">
          <i className={iconName}></i>
          <h3>{title}</h3>
        </div>
      </div>
    </section>
  );
};

export default HomeCardOne;