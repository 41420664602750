import trustClient01 from "@/images/home_1/4.webp"
import resp_img from "@/images/resp-img.webp"

export const TrustClientData = {
  image: trustClient01,
  respimage: resp_img,
  // title: "Your scores, your way, LIVE in your devices",
  text: "Please check our unique score visualisation apps for Apple and Android platforms.",
  url: "/about",
}
