import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Navigation, EffectFade } from "swiper"
import { Col, Container, Row } from "react-bootstrap"
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { SliderData } from "./data"
import SliderButton from "./slider-button"

SwiperCore.use([Autoplay, Navigation, EffectFade])
const TabSlider = () => {
  const mainSlideOptions = {
    slidesPerView: 1,
    direction: "horizontal",
    loop: true,
    effect: "fade",
    navigation: {
      nextEl: "#main-slider-next",
      prevEl: "#main-slider-prev",
    },
    autoplay: {
      delay: 5000,
    },
  }

  return (
    <section className="main-slider d-none d-md-block d-lg-none">
      <Swiper {...mainSlideOptions}>
        {SliderData.map(({ Tabimage, subTitle, title, button }, index) => (
          <SwiperSlide key={index}>
            <div
              className="image-layer"
              style={{ backgroundImage: `url(${Tabimage})` }}
            ></div>
            <Container>
              <Row>
                <Col lg={12} className="text-center">
                  <p className="main-slider__subtext">{subTitle}</p>
                  <h3 className="main-slider__title">{title}</h3>
                  <SliderButton button={button} />
                </Col>
              </Row>
            </Container>
          </SwiperSlide>
        ))}
        <div className="swiper-button-prev" id="main-slider-prev">
          <i className="fa fa-angle-left"></i>
        </div>
        <div className="swiper-button-next" id="main-slider-next">
          <i className="fa fa-angle-right"></i>
        </div>
        {/* <ParticlesBg color="#cccccc" type="cobweb" num={100} bg={true} /> */}
      </Swiper>
    </section>
  )
}

export default TabSlider
